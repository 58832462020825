import React, {useCallback, useContext, useEffect,  useState} from "react"
import {GlobalStateContext} from "../context/GlobalContextProvider"
import Layout from '../layouts'

import AccessCheck from '../components/attendance/accessCheck'
import Cfptb from "../components/cfp/cfptb";

import {Buffer} from "buffer";

import {CmHistoryExt} from "../components/CmHistoryExt";
import CmDropdownSelect from "../components/CmDropdownSelect";
import {Box} from "@mui/system";
import {Typography} from "@mui/material";


const History = ({location}) => {

    const { gCompanyDevice, gCfp} =
        useContext(GlobalStateContext)
    const [history, setHistory] = useState([])
    const [lastKey, setLastKey] = useState('')
    const [deviceId, setDeviceId] = useState('')

    const [isAll, setIsAll] = useState(true)
    const [listDevices, setListDevices] = useState([])
    const [allHistory, setAllHistory] = useState([])
    //const [deviceId, isAll] = useState('')
    const limit = 50
    const [isLoading, setIsLoading] = useState(false);

    const loadData = useCallback((device, time) => {
        if (isLoading) return;
        setIsLoading(true);
        setDeviceId(device)
      //  console.log("准备请求数据", 'start')
        gCompanyDevice.getDeviceHistory(device, time, limit, (d) => {
         //   console.log("准备请求数据", d)
            setIsLoading(false); // 完成数据加载

            if (d) {
                let list = d.list.map(item => {
                    if (item.history_tag) {
                        item.history_tag = Buffer.from(
                            item.history_tag,
                            'base64',
                        ).toString('utf8')
                    }else {
                        if (item.type===7){
                            item.history_tag='手動施錠'
                        }
                        if (item.type===8){
                            item.history_tag='手動解錠'
                        }
                    }

                    return item

                })
                setLastKey(d.lastKey)
                setHistory(prevState => [...prevState, ...list])
            }
        })
        // ... 加载数据逻辑 ...
    }, [isLoading, gCompanyDevice]);
    const loadAll = useCallback(async (isFirst, devices) => {
        if (isLoading) return;

        setIsLoading(true);
        if (!isFirst&&listDevices.length===0){

            return
        }
     //   console.log("开始请求数据",isLoading,devices)

        let list = devices ? devices : listDevices
        gCompanyDevice.getAllHistory(list, (d) => {
            setIsLoading(false); // 完成数据加载

           // console.log("开始请求数据DD",isLoading,d)
            if (d) {
                let allHistory = []
                let mListDevice = []
                d.map(listItem => {
                    if (listItem.list.length > 0) {
                        listItem.list.map(item => {

                            console.log("shebeisadasdada",item)
                            if (item.history_tag) {
                                item.history_tag = Buffer.from(item.history_tag, 'base64',).toString('utf8')
                            }else {
                                if (item.type===7){
                                    item.history_tag='手動施錠'
                                }
                                if (item.type===8){
                                    item.history_tag='手動解錠'
                                }
                            }



                            allHistory.push(item)
                        })
                        if (listItem.lastKey){
                            mListDevice.push({deviceId: listItem.deviceId, lastKey: listItem.lastKey})
                        }

                    }
                })
                setListDevices(mListDevice)
                allHistory.sort((a, b) => b.timestamp - a.timestamp);
                setAllHistory(prevState => [...prevState,...allHistory])
                setHistory(prevState => [...prevState,...allHistory])


            }
        })
    }, [isLoading, gCompanyDevice, listDevices]);


    useEffect(() => {
        // 定义滚动事件处理函数
        const handleScroll =async () => {
            // 滚动位置 + 视窗高度
            const scrolled = window.scrollY + window.innerHeight;
            let maxHeight = history.length * 70 * 0.8
            if (scrolled > maxHeight) {
                if (isAll) {
                await    loadAll(false)
                } else {
                    if (lastKey !== undefined && lastKey !== '' && lastKey !== null) {
                        loadData(deviceId, lastKey)
                    }
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        // 清理函数：组件卸载时移除事件监听器
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [history.length, isAll, lastKey, deviceId, loadAll, loadData]); // 添加了 isAll 和 deviceId

   useEffect(()=>{

       console.log("当前历史记录",history)


   },[history])


    const callSelect = (value) => {
       // console.log("选择的值", value, isAll)

        if (value !== '0') {
            setHistory([])

            setIsAll(false)
            loadData(value, undefined)
        } else {
            setIsAll(true)
            setHistory(allHistory)
        }

    }
    useEffect(()=>{




        loadAll(true,gCfp.cfpSsmDevices.map(item => {
            return {deviceId: item.deviceUUID}
        }))
    },[gCfp.cfpSsmDevices])

    return (

        <Layout location={location}>
            <AccessCheck access={'デバイス'}>
            <Box sx={{ margin: '15px 0 15px 15px'}}>
                    <Typography variant="h2" sx={{fontWeight: "bold", marginTop: '20px',marginBottom: "16px"}}>
                        {'全体履歴'}
                    </Typography>

                    <CmDropdownSelect callSelect={callSelect} itemsSelect={gCfp.cfpSsmDevices}/>

                <Cfptb
                    selectableRows={'none'}
                    isAdd={false}
                    isBack={false}
                    isSelect={true}
                    data={history}
                    isShowFooter={false}
                    isLine={false}
                    isBind={false}
                    isDel={false}
                    isCsv={false}
                    isHideToolBar={true}
                    text={""}
                    columns={CmHistoryExt.Columns({devices:gCfp.cfpSsmDevices})}
                />

            </Box>
            </AccessCheck>

        </Layout>

    )

}
export default History
