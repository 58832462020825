import BluetoothIcon from "@mui/icons-material/Bluetooth";
import MotionPhotosAutoIcon from "@mui/icons-material/MotionPhotosAuto";
import PanToolIcon from "@mui/icons-material/PanTool";
import WifiIcon from "@mui/icons-material/Wifi";
import ComputerRoundedIcon from "@mui/icons-material/ComputerRounded";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import {Typography} from "@mui/material";
import React from "react";

const style = {
    color: '#ffffff',
    fontSize: '30px',
    padding: '5px',
    borderRadius: '100%',
}
const ViaView = ({type}) => {
    switch (type) {
        case 1:
        case 2:
        case 18:
            return (
                <BluetoothIcon
                    sx={{
                        color: (theme) => theme.palette.info.light,
                    }}
                />
            )
        case 6:
            return (
                <MotionPhotosAutoIcon
                    sx={{
                        color: (theme) => theme.palette.info.light,
                    }}
                />
            )
        case 7:
        case 8:
            return (
                <PanToolIcon
                    sx={{
                        color: (theme) => theme.palette.info.light,
                    }}
                />
            )
        case 14:
        case 15:
        case 19:
            return (
                <WifiIcon
                    sx={{
                        color: (theme) => theme.palette.info.light,
                    }}
                />
            )
        case 16:
        case 17:
        case 20:
            return (
                <ComputerRoundedIcon
                    sx={{
                        color: (theme) => theme.palette.info.light,
                    }}
                />
            )
        default:
            return ''
    }
}
const StatusView = ({type}) => {
    switch (type) {
        case 1:
        case 6:
        case 7:
        case 14:
        case 16:
            return (
                <LockOutlinedIcon sx={{ ...style, backgroundColor: 'error.light' }} />
            )
        case 2:
        case 8:
        case 15:
        case 17:
            return (
                <LockOpenOutlinedIcon
                    sx={{ ...style, backgroundColor: 'primary.light' }}
                />
            )
        case 18:
        case 19:
        case 20:
            return (
                <TouchAppIcon sx={{ ...style, backgroundColor: 'primary.light' }} />
            )

        default:
            return ''
    }
}
const Columns = ({devices}) => [

    {
        name: "type",
        label: "状態",
        options: {


            customBodyRender: (value, tableMeta, updateValue) => {




                return   (<StatusView type={value}/>)
            }

        },

    },
    {
        name: "timestamp",
        label: "時間",
        options: {
            filter: false,

            customBodyRender: (value, tableMeta, updateValue) => {



                return (
                    <Typography variant="h5">
                        {new Date(
                            value
                        ).toLocaleString('ja-JP', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                        })}{' '}
                        {new Date(
                            value
                        ).toLocaleTimeString('ja-JP', { hour12: true })}
                    </Typography>

                )
            }

        },

    },
    {
        name: "device_id",
        label: "デバイス名",
        options: {
            customBodyRender: (value, tableMeta, updateValue) => {

                let  device=devices.find(item=>item.deviceUUID===value)

                console.log("找到设备",device)





                return   (<Typography >{device.deviceName||value}</Typography>)
            }

        },
    },
    {name: "history_tag", label: "ユーザー名"},
    {
        name: "type",
        label: "操作方法",
        options: {


            customBodyRender: (value, tableMeta, updateValue) => {






                return   (<ViaView type={value}/>)
            }

        },

    }

]
export const CmHistoryExt={
    Columns,
    ViaView,
    StatusView
}
